// eslint-disable-next-line no-redeclare,no-unused-vars
function registerTable(element, object, param = {}) {
  var objectSub = param.objectSub;
  var settings = parameter[object];
  if (!settings) settings = {};

  var where = {disabled: 0};
  if (param.where) $.extend(true, where, param.where);

  if (settings.where) $.extend(true, where, settings.where);
  if (objectSub) {
    var match = objectSub.match(/^([a-z]+)[A-Z0-9]/);
    if (match) {
      var whereTop = optionValue({
        settings: settings,
        objectSub: match[1],
        key: 'where',
      });
      if (whereTop) $.extend(true, where, whereTop);
    }
    // if (match && parameter[object]['where' + ucfirst(match[1])]) where = $.extend(true, where, parameter[object]['where' + ucfirst(match[1])]); // top object filter
    if (settings['where' + ucfirst(objectSub)])
      where = $.extend(true, where, settings['where' + ucfirst(objectSub)]);
  }

  // whre from ajax call
  if (param.send && param.send.where) {
    $.extend(true, where, param.send.where);
    element.filter = param.send.where;
  }

  if (element.where && !isEmptyObject(element.where))
    $.extend(true, where, element.where);
  else element.where = where;

  var hidden = optionValue({
    settings: settings,
    objectSub: objectSub,
    key: 'hidden',
  });
  if (hidden) {
    var filter = hidden[role];
    if (!filter)
      $.each(hidden, function (roleValue, filterValue) {
        if (str_starts_with(roleValue, '!')) {
          if (str_contains(roleValue, ',')) {
            if (!inArray(role, roleValue.slice(1).split(','))) {
              filter = filterValue;
              return false;
            }
          } else if (roleValue.slice(1) != role) {
            filter = filterValue;
            return false;
          }
        }
      });
    if (filter && strpos(filter, ',') > 0) {
      var key1, key2;
      [key1, key2] = filter.split(',');
      where[key1] = user[key2];
    } else if (filter) where[filter] = user[filter];
  }

  element.filterJs = convertWhere(where, {object: object});
  if (!elements.tables[object + '_els']) elements.tables[object + '_els'] = [];
  elements.tables[object + '_els'].push(element);
}
